<template>
  <div class="rating_wrap">
    <div class="rating">
      <div
        class="star"
        :class="{ filled: value.filled }"
        v-for="(value, index) in this.stars"
        :key="index"
        @click="this.click_star(value.score)"
        @mouseover="this.over_star(value.score)"
        @mouseleave="this.leave_star"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Rating",
  props: ["rate", "readonly"],
  data: function () {
    return {
      stars: [
        { score: 1, filled: true },
        { score: 2, filled: true },
        { score: 3, filled: true },
        { score: 4, filled: true },
        { score: 5, filled: true },
      ],
      rating:
        typeof this.rate == "undefined" || this.rate <= 0
          ? 5
          : parseInt(this.rate),
      editable: !this.readonly,
    };
  },
  mounted() {
    this.init_data();
  },
  methods: {
    init_data: function () {
      let target = parseInt(this.rating);
      for (let i = 0; i < this.stars.length; i++) {
        if (this.stars[i].score <= target) {
          this.stars[i].filled = true;
        } else {
          this.stars[i].filled = false;
        }
      }
    },
    over_star: function (score) {
      // 읽기 전용이면 아무것도 안함
      if (!this.editable) return;

      for (let i = 0; i < this.stars.length; i++) {
        if (this.stars[i].score <= score) {
          this.stars[i].filled = true;
        } else {
          this.stars[i].filled = false;
        }
      }
    },
    leave_star: function () {
      // 읽기 전용이면 아무것도 안함
      if (!this.editable) return;

      for (let i = 0; i < this.stars.length; i++) {
        if (this.stars[i].score <= this.rating) {
          this.stars[i].filled = true;
        } else {
          this.stars[i].filled = false;
        }
      }
    },
    click_star: function (score) {
      // 읽기 전용이면 아무것도 안함
      if (!this.editable) return;

      this.rating = parseInt(score);
      this.$emit("rate_change", this.rating);
    },
  },
};
</script>

<style scoped>
.rating_wrap {
  display: block;
}
.rating_wrap > .rating {
  position: relative;
  width: 240px;
  max-width: 100%;
  margin: 0 auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.rating_wrap > .rating > .star {
  position: relative;
  width: calc(100% / 5 - 0.1px);
  background: url("../../assets/img/icon_star_empty.svg") no-repeat center
    center;
  background-size: contain;
  cursor: pointer;
}
.rating_wrap > .rating > .star::after {
  content: "";
  display: block;
  padding-top: 100%;
}
.rating_wrap > .rating > .star:last-child {
  margin-right: 0;
}
.rating_wrap > .rating > .star.filled {
  background-image: url("../../assets/img/icon_star_full.svg");
}
</style>
