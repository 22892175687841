<template>
  <div class="header">
    <h1>{{ this.$t("mypage.order_history") }}</h1>
    <ul class="path">
      <li>
        <router-link to="/mypage">{{ this.$t("mypage.mypage") }}</router-link>
      </li>
      <li class="current">
        <router-link to="/mypage/order">{{
          this.$t("mypage.order_history")
        }}</router-link>
      </li>
    </ul>
  </div>
  <div class="body">
    <h3>{{ this.$t("mypage.order_info") }}</h3>
    <ul class="info_list">
      <li>
        <div class="title">{{ this.$t("mypage.order_no") }}</div>
        <div class="value">
          <span class="order_number">{{ this.order_id }}</span>
        </div>
      </li>
      <li>
        <div class="title">{{ this.$t("mypage.order_date") }}</div>
        <div class="value">
          {{
            this.common.date_long_format(
              this.common.date_parse(this.order_date)
            )
          }}
        </div>
      </li>
      <li>
        <div class="title">{{ this.$t("mypage.order_status") }}</div>
        <div class="value">
          <div class="status">
            {{ this.convert_status_text(this.order_status) }}
          </div>
          <div
            class="request"
            v-if="
              this.order_status == 'ORDER' &&
              typeof this.cancel_request_status != 'undefined' &&
              this.cancel_request_status != null &&
              this.cancel_request_status != ''
            "
          >
            {{
              this.convert_request_cancel_status_text(
                this.cancel_request_status
              )
            }}
          </div>
        </div>
      </li>
    </ul>

    <h3>{{ this.$t("mypage.product_info") }}</h3>
    <ul class="product_list">
      <li class="empty" v-if="this.product_data.length <= 0">
        {{ this.$t("common.no_data") }}
      </li>
      <li v-for="(value, index) in product_data" :key="index">
        <div
          class="image"
          :style="{
            backgroundImage: 'url(' + value.product_image_url + ')',
          }"
        ></div>
        <div class="info">
          <div class="seller">{{ value.seller_name }}</div>
          <div class="name">{{ value.product_name }}</div>
          <div class="option">
            <span>{{ value.product_option }}</span>
            <div class="count">
              {{ value.product_count }}{{ this.$t("order.count") }}
            </div>
          </div>
          <div class="price">
            {{
              this.common.currency_format(
                (value.product_price + value.option_price) *
                  value.product_count,
                value.currency
              )
            }}
          </div>
          <div
            class="track"
            v-if="
              this.order_status == 'SHIPPED' || this.order_status == 'DELIVERED'
            "
            @click="
              this.click_tracking(value.tracking_number, value.shipping_company)
            "
          >
            배송추적
          </div>
          <div class="review">
            <div
              class="write"
              v-if="
                (typeof value.review == 'undefined' || value.review == null) &&
                this.order_status == 'DELIVERED'
              "
              @click="this.open_write_review_popup(value)"
            >
              {{ this.$t("mypage.write_review") }}
            </div>
            <div
              class="view"
              @click="this.open_view_review_popup(value.review)"
              v-else-if="
                typeof value.review != 'undefined' && value.review != null
              "
            >
              {{ this.$t("mypage.view_review") }}
            </div>
          </div>
        </div>
      </li>
    </ul>

    <h3>{{ this.$t("mypage.shipping_info") }}</h3>
    <ul class="info_list">
      <li>
        <div class="title">{{ this.$t("mypage.recipient") }}</div>
        <div class="value">{{ this.recipient }}</div>
      </li>
      <li>
        <div class="title">{{ this.$t("mypage.phone") }}</div>
        <div class="value">{{ this.mobile }}</div>
      </li>
      <li>
        <div class="title">{{ this.$t("mypage.address") }}</div>
        <div class="value">
          <div class="country">
            {{ this.common.get_country_name_by_code3(this.shipping_country) }}
          </div>
          ({{ this.shipping_postal_code }}) {{ this.shipping_address }}
          {{ this.shipping_address_detail }}
        </div>
      </li>
      <li>
        <div class="title">{{ this.$t("mypage.delivery_message") }}</div>
        <div class="value">{{ this.delivery_message }}</div>
      </li>
    </ul>

    <h3>{{ this.$t("mypage.payment_info") }}</h3>
    <ul class="info_list">
      <li>
        <div class="title">{{ this.$t("mypage.product_price") }}</div>
        <div class="value">
          {{ this.common.currency_format(this.total_price, this.currency) }}
        </div>
      </li>
      <li>
        <div class="title">{{ this.$t("mypage.coupon_discount") }}</div>
        <div class="value">
          {{ this.common.currency_format(this.coupon_discount, this.currency) }}
        </div>
      </li>
      <li>
        <div class="title">{{ this.$t("mypage.point_discount") }}</div>
        <div class="value">
          {{ this.common.currency_format(this.used_point, this.currency) }}
        </div>
      </li>
      <li>
        <div class="title">{{ this.$t("mypage.total_price") }}</div>
        <div class="value">
          {{ this.common.currency_format(this.payment_price, this.currency) }}
        </div>
      </li>
    </ul>
  </div>

  <div class="footer">
    <button class="button middle" @click="this.$router.push('/mypage/order')">
      {{ this.$t("mypage.goto_list") }}
    </button>
    <button
      class="button middle outline"
      v-if="this.order_status == 'ORDER' && this.cancel_request_status == ''"
      @click="this.try_request_order_cancel"
    >
      {{ this.$t("mypage.request_cancel") }}
    </button>
  </div>

  <Transition name="popup">
    <div
      class="overlay"
      v-if="this.show_review_popup"
      @click="this.close_review_popup"
    ></div>
  </Transition>
  <Transition name="popup">
    <div class="review_popup_wrap popup_wrap" v-if="this.show_review_popup">
      <div class="header">
        <span v-if="this.review_data.mode == 'WRITE'">{{
          this.$t("mypage.write_review")
        }}</span>
        <span v-else-if="this.review_data.mode == 'VIEW'">{{
          this.$t("mypage.view_review")
        }}</span>
        <div class="close" @click="this.close_review_popup"></div>
      </div>
      <div class="body">
        <div class="write" v-if="this.review_data.mode == 'WRITE'">
          <div class="product">
            <span class="name">{{ this.review_data.product_name }}</span>
            <span class="option" v-if="this.review_data.product_option">{{
              this.review_data.product_option
            }}</span>
          </div>
          <Rating
            class="rate"
            :rate="this.review_data.score"
            @rate_change="this.change_review_score"
          ></Rating>
          <textarea
            v-model="this.review_data.content"
            :placeholder="this.$t('mypage.review_placeholder')"
          ></textarea>
          <h3>{{ this.$t("mypage.review_image") }}</h3>
          <input
            type="file"
            class="image_upload"
            ref="review_image_upload"
            accept="image/*"
          />
          <div class="images">
            <div
              v-for="(value, index) in this.review_data.images"
              :key="index"
              :style="{ backgroundImage: 'url(' + value.url + ')' }"
              class="image"
            >
              <div
                class="delete"
                @click="this.delete_review_image(index)"
              ></div>
            </div>

            <div
              class="upload"
              @click="this.click_review_image_upload"
              v-if="this.review_data.images.length < 3"
            ></div>
          </div>
        </div>
        <!-- write -->

        <div class="view" v-else-if="this.review_data.mode == 'VIEW'">
          <Rating
            :rate="this.review_data.score"
            :readonly="true"
            class="score"
          ></Rating>
          <div class="date">{{ this.review_data.date }}</div>
          <div class="content">{{ this.review_data.content }}</div>
          <div class="images">
            <div
              class="image"
              v-for="(value, index) in this.review_data.images"
              :key="index"
              :style="{ backgroundImage: 'url(' + value.url + ')' }"
            ></div>
          </div>
        </div>
        <!-- view -->
      </div>
      <div class="footer" v-if="this.review_data.mode != 'VIEW'">
        <button class="button small" @click="this.click_save_review">
          {{ this.$t("mypage.save") }}
        </button>
      </div>
    </div>
  </Transition>

  <Spinner v-show="processing" />
</template>

<script>
import Rating from "../product/Rating.vue";
export default {
  name: "MyOrder",
  components: {
    Rating,
  },
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.myorder"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.myorder"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      product_data: [],
      log_data: [],

      // 주문 정보
      order_id: 0,
      invoice_token: "",
      total_price: 0,
      used_point: 0,
      used_coupon_id: 0,
      coupon_discount: 0,
      payment_price: 0,
      payment_method: "",
      currency: "",
      order_status: "",
      recipient: "",
      mobile: "",
      shipping_country: "",
      shipping_postal_code: "",
      shipping_address: "",
      shipping_address_detail: "",
      delivery_message: "",
      order_date: "",

      cancel_request_status: "",

      unit: "KRW",

      show_review_popup: false,
      review_data: {
        review_id: 0,
        order_product_id: 0,
        product_id: 0,
        order_id: 0,
        product_name: "",
        product_option: "",
        score: 5,
        content: "",
        images: [],
        date: "",
        mode: "WRITE",
      },
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.init_data();
    this.load_order_data();
  },
  methods: {
    init_data: function () {
      // 주문 아이디 가져오기
      this.order_id = this.$route.params.order_id;
    },
    load_order_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/order/get",
          {
            user_id: self.storage.get_user_id(),
            order_id: self.order_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          const { order, product, log } = response.data.data;

          // 제품 정보
          for (let i = 0; i < product.length; i++) {
            let str_images = product[i].product_images;
            let arr_images = str_images.split("|");

            let image_url = "";
            if (arr_images.length >= 1) {
              image_url =
                process.env.VUE_APP_API_URL +
                "/resource/get/product/" +
                product[i].product_id.toString() +
                "/" +
                arr_images[0];
            }

            product[i].product_image_url = image_url;
          }

          self.product_data = product;

          // 주문 정보
          self.invoice_token = order.invoice_token;
          self.total_price = order.total_price;
          self.used_point = order.used_point;
          self.used_coupon_id = order.used_coupon_id;
          self.coupon_discount = order.coupon_discount;
          self.payment_price = order.payment_price;
          self.payment_method = order.payment_method;
          self.currency = order.currency;
          self.order_status = order.order_status;
          self.recipient = order.recipient;
          self.mobile = order.mobile;
          self.shipping_country = order.shipping_country;
          self.shipping_postal_code = order.shipping_postal_code;
          self.shipping_address = order.shipping_address;
          self.shipping_address_detail = order.shipping_address_detail;
          self.delivery_message = order.delivery_message;
          self.order_date = order.order_date;

          // 주문 취소 신청 정보
          if (
            typeof order.cancel_request_status != "undefined" &&
            order.cancel_request_status != null
          ) {
            self.cancel_request_status = order.cancel_request_status;
          } else {
            self.cancel_request_status = "";
          }

          // 로그 정보
          self.log_data = log;
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_order_data

    // 주문 상태 표기 텍스트
    convert_status_text: function (status) {
      if (status.trim().toUpperCase() == "ORDER") {
        return this.$t("mypage.ordered");
      } else if (status.trim().toUpperCase() == "PREPARE") {
        return this.$t("mypage.preparing");
      } else if (status.trim().toUpperCase() == "SHIPPED") {
        return this.$t("mypage.shipped");
      } else if (status.trim().toUpperCase() == "DELIVERED") {
        return this.$t("mypage.delivered");
      } else if (status.trim().toUpperCase() == "CANCEL") {
        return this.$t("mypage.return");
      }

      return "";
    },

    // 리뷰 데이터 초기화
    reset_review_data: function () {
      this.review_data = {
        order_product_id: 0,
        review_id: 0,
        product_id: 0,
        order_id: 0,
        product_name: "",
        product_option: "",
        score: 5,
        content: "",
        images: [],
        date: "",
        mode: "WRITE",
      };
    }, //-- reset_review_data

    // 리뷰 팝업 닫기
    close_review_popup: function () {
      this.show_review_popup = false;
      this.reset_review_data();
    },

    // 리뷰 작성 팝업 열기
    open_write_review_popup: function (product_item) {
      this.reset_review_data();
      this.review_data.order_product_id = product_item.order_product_id;
      this.review_data.order_id = this.order_id;
      this.review_data.product_id = product_item.product_id;
      this.review_data.product_name = product_item.product_name;
      this.review_data.product_option = product_item.product_option;

      this.show_review_popup = true;
    },

    // 리뷰 점수 변경 이벤트 핸들러
    change_review_score: function (score) {
      this.review_data.score = score;
    },

    // 리뷰 이미지 업로드 버튼 클릭 처리
    click_review_image_upload: function () {
      let obj = this.$refs.review_image_upload;

      try {
        obj.removeEventListener("change", this.change_review_image_upload);
        obj.value = "";
        obj.addEventListener("change", this.change_review_image_upload);
      } catch (error) {
        console.log(error);
      }

      obj.click();
    },

    // 이미지 업로드 엘리먼트 변경 이벤트 핸들러
    change_review_image_upload: function (event) {
      this.upload_review_image(event);
    },

    // 이미지 업로드 수행
    upload_review_image: function (event) {
      if (!this.storage.is_logged_in()) return;

      if (!event.target.files || !event.target.files[0]) return;

      const data = new FormData();
      data.append("file", event.target.files[0]);

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(process.env.VUE_APP_API_URL + "/review/image/upload", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.data.result != "OK") {
            this.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          let filename = response.data.filename;
          let fullpath = response.data.fullpath;

          if (self.review_data.images.length < 3) {
            let item = {
              name: filename,
              url: fullpath,
            };
            self.review_data.images.push(item);
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    },

    // 리뷰 이미지 삭제
    delete_review_image: function (index) {
      if (this.review_data.images.length) {
        if (typeof this.review_data.images[index] != "undefined") {
          this.review_data.images.splice(index, 1);
        }
      }
    },

    // 리뷰 저장 버튼 클릭 처리
    click_save_review: function () {
      let self = this;
      if (this.review_data.content.trim().length < 10) {
        self.$swal.fire({
          text: self.$t("mypage.error_review_content"),
          icon: "error",
        });
        return;
      }

      this.$swal
        .fire({
          text: this.$t("mypage.confirm_save"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.add_review_data();
          }
        }); //-- swal
    },

    // 리뷰 데이터 서버에 저장
    add_review_data: function () {
      if (!this.storage.is_logged_in()) return;

      if (this.review_data.content.trim().length < 10) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/review/add",
          {
            user_id: self.storage.get_user_id(),
            order_id: self.order_id,
            order_product_id: self.review_data.order_product_id,
            product_id: self.review_data.product_id,
            product_option: self.review_data.product_option,
            review_content: self.review_data.content,
            review_score: self.review_data.score,
            review_image1:
              typeof self.review_data.images[0] != "undefined"
                ? self.review_data.images[0].name
                : "",
            review_image2:
              typeof self.review_data.images[1] != "undefined"
                ? self.review_data.images[1].name
                : "",
            review_image3:
              typeof self.review_data.images[2] != "undefined"
                ? self.review_data.images[2].name
                : "",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          // 리뷰 작성 팝업 닫기
          self.close_review_popup();

          // 주문 정보 다시 로드하기
          self.load_order_data();
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- add_review_data

    // 리뷰 이미지 경로 가져오기
    get_review_image_url: (review_id, filename) => {
      return (
        process.env.VUE_APP_API_URL +
        "/resource/get/review/" +
        review_id +
        "/" +
        filename
      );
    }, //-- get_review_image_url

    // 리뷰 보기 팝업 열기
    open_view_review_popup: function (data) {
      this.reset_review_data();
      this.review_data.review_id = data.review_id;
      this.review_data.order_product_id = data.order_product_id;
      this.review_data.product_id = data.product_id;
      this.review_data.order_id = data.order_id;
      this.review_data.product_option = data.product_option;
      this.review_data.content = data.review_content;
      this.review_data.score = data.review_score;

      // 이미지 처리
      if (
        typeof data.review_image1 != "undefined" &&
        data.review_image1 != null &&
        data.review_image1 != ""
      ) {
        this.review_data.images.push({
          url: this.get_review_image_url(data.review_id, data.review_image1),
        });
      }

      if (
        typeof data.review_image2 != "undefined" &&
        data.review_image2 != null &&
        data.review_image2 != ""
      ) {
        this.review_data.images.push({
          url: this.get_review_image_url(data.review_id, data.review_image2),
        });
      }

      if (
        typeof data.review_image3 != "undefined" &&
        data.review_image3 != null &&
        data.review_image3 != ""
      ) {
        this.review_data.images.push({
          url: this.get_review_image_url(data.review_id, data.review_image3),
        });
      }

      // 보기 모드
      this.review_data.mode = "VIEW";

      // 작성일 정제
      this.review_data.date = this.common.date_long_format(
        this.common.date_parse(data.register_date)
      );

      this.show_review_popup = true;
    }, //-- open_view_review_popup

    // 주문 취소 신청 물어보기
    try_request_order_cancel: function () {
      let self = this;
      this.$swal
        .fire({
          text: self.$t("mypage.confirm_order_cancel_request"), //"주문 취소 신청을 하시겠습니까?",
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.request_order_cancel();
          }
        });
    }, //-- try_request_order_cancel

    // 주문 취소 신청
    request_order_cancel: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/order/request/cancel",
          {
            user_id: self.storage.get_user_id(),
            order_id: self.order_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result == "ERR_NOT_ORDER_STATUS") {
            // 취소 신청은 주문 배송 전까지만 가능합니다.
            self.$swal.fire({
              text: self.$t("mypage.error_order_cancel_request_status"),
              icon: "error",
            });
            return;
          } else if (response.data.result == "ERR_ALREADY_REQUEST") {
            // 이미 취소 신청이 되어있습니다.
            self.$swal.fire({
              text: self.$t("mypage.error_already_request_cancel"),
              icon: "error",
            });
            return;
          } else if (response.data.result != "OK") {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          // 취소 신청이 완료되었습니다. 취소가 완료되면 알려드릴께요.
          self.$swal.fire({
            text: self.$t("mypage.order_cancel_request_ok"),
            icon: "success",
          });

          // 주문 정보 다시 로드하기
          self.load_order_data();
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- add_review_data

    // 취소 신청 상태 텍스트
    convert_request_cancel_status_text: function (status) {
      switch (status) {
        case "REQUEST":
          return this.$t("mypage.status_request_cancel");
        case "COMPLETE":
          return this.$t("mypage.status_complete_cancel");
        case "REJECT":
          return this.$t("mypage.status_reject_cancel");
      }

      return "";
    }, //-- convert_request_cancel_status_text

    click_tracking: function (tracking_no, shipping_company) {
      let lp = (screen.width - 770) / 2;
      let tp = (screen.height - 600) / 2;

      let popup_title = "TRACKING";

      if (shipping_company == "EMS") {
        let form = document.createElement("form");
        form.style.display = "none";
        form.target = popup_title;
        form.method = "POST";
        form.action =
          "https://trace.epost.go.kr/xtts/servlet/kpl.tts.common.svl.SttSVL";

        let input_code = document.createElement("input");
        input_code.type = "text";
        input_code.name = "POST_CODE";
        input_code.value = tracking_no;
        form.appendChild(input_code);

        let input_command = document.createElement("input");
        input_command.type = "text";
        input_command.name = "target_command";
        input_command.value = "kpl.tts.tt.epost.cmd.RetrieveEmsTraceEngCmd";
        form.appendChild(input_command);

        let input_uri = document.createElement("input");
        input_uri.type = "text";
        input_uri.name = "JspURI";
        input_uri.value = "/xtts/tt/epost/ems/EmsSearchResultEng.jsp";
        form.appendChild(input_uri);

        document.body.appendChild(form);

        let win = window.open(
          "",
          popup_title,
          "width=770, height=600, scrollbars=yes, left=" + lp + ",top=" + tp
        );

        if (win) form.submit();
      } else {
        let ship_id = "kr.cjlogistics";
        if (shipping_company == "CJ") ship_id = "kr.cjlogistics";
        else if (shipping_company == "LOGEN") ship_id = "kr.logen";
        else if (shipping_company == "KPOST") ship_id = "kr.epost";

        let url = "https://tracker.delivery/#/" + ship_id + "/" + tracking_no;

        window.open(
          url,
          popup_title,
          "width=770, height=600, scrollbars=yes, left=" + lp + ",top=" + tp
        );
      }
    },
  },
};
</script>

<style scoped>
.body > h3 {
  font-size: 1.4rem;
  font-weight: 700;

  padding: 0;
  margin: 2rem 0 0.4rem 0;
}

.body > ul.product_list {
  list-style-type: none;
  margin: 0 0 2rem 0;
  padding: 0;

  border-top: 1px solid #808080;
}
.body > ul.product_list > li {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0;
  margin: 0;
  border-bottom: 1px solid #dedede;
}
.body > ul.product_list > li.empty {
  padding: 4rem 0;
  text-align: center;
}
.body > ul.product_list > li > .image {
  width: 96px;
  height: 96px;
  background: #efefef;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.body > ul.product_list > li > .info {
  position: relative;
  flex-grow: 1;
  margin: 0 0 0 1rem;
  padding: 0 3rem 0 0;
}
.body > ul.product_list > li > .info > .seller {
  font-weight: 400;
  color: #808080;
  margin: 0 0 0.2rem 0;
}
.body > ul.product_list > li > .info > .name {
  font-size: 1.1rem;
  font-weight: 700;
  color: #000;
  margin: 0 0 0.4rem 0;
}
.body > ul.product_list > li > .info > .option {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-weight: 300;
  color: #808080;
  margin: 0 0 0.4rem 0;
}
.body > ul.product_list > li > .info > .option > span {
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid #dedede;
}
.body > ul.product_list > li > .info > .option > .count {
  padding: 0;
}
.body > ul.product_list > li > .info > .price {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.1rem;
  font-weight: 700;
}
.body > ul.product_list > li > .info > .track {
  display: inline-block;
  vertical-align: middle;

  font-size: 0.875rem;
  font-weight: 400;

  padding: 0.3rem 0.6rem;
  margin: 0 0 0 0.4rem;
  background: #333;
  color: #fff;
  border-radius: 0.6rem;

  cursor: pointer;
}
.body > ul.product_list > li > .info > .review {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
}
.body > ul.product_list > li > .info > .review > .write,
.body > ul.product_list > li > .info > .review > .view {
  font-size: 1rem;
  padding: 0.4rem 0.8rem;
  border-radius: 3rem;
  border: 1px solid #000;
  cursor: pointer;
}
.body > ul.product_list > li > .info > .review > .write {
  background: #e74356;
  color: #fff;
  border-color: #e74356;
}

.body > ul.info_list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  border-top: 1px solid #808080;
  border-bottom: 1px solid #dedede;
}
.body > ul.info_list > li {
  position: relative;
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.body > ul.info_list > li > .title {
  width: 140px;
  max-width: 30%;
  background: #f4f4f4;
  font-weight: 700;
  padding: 0.8rem 1rem;
}
.body > ul.info_list > li > .value {
  flex-grow: 1;
  padding: 0.8rem 1rem;
}
.body > ul.info_list > li > .value > .country {
  margin-bottom: 0.4rem;
  font-weight: 700;
}
.body > ul.info_list > li > .value > .order_number {
  color: #e74356;
  font-weight: 700;
}
.body > ul.info_list > li > .value > .status {
  display: inline-block;
  vertical-align: middle;
}
.body > ul.info_list > li > .value > .request {
  display: inline-block;
  vertical-align: middle;

  margin: 0 0 0 1rem;
  padding: 0.4rem 0.8rem;
  font-size: 0.7rem;
  font-weight: 700;

  color: #fff;
  background: #333;
  border-radius: 3rem;
}

.body > button.more {
  display: block;
  margin: 2rem auto 0 auto;
}

.footer {
  margin: 2rem 0 0 0;
  text-align: center;
}
.footer > button.button {
  margin: 0 0.4rem;
}

.review_popup_wrap > .body > .write > .product {
  margin: 0 0 1rem 0;
}
.review_popup_wrap > .body > .write > .product > span {
  display: inline-block;
  vertical-align: middle;
}
.review_popup_wrap > .body > .write > .product > .name {
  font-weight: 700;
}
.review_popup_wrap > .body > .write > .product > .option {
  margin: 0 0 0 0.8rem;
  padding: 0 0 0 0.8rem;
  border-left: 1px solid #dedede;
  font-weight: 300;
  color: #808080;
}
.review_popup_wrap > .body > .write > .rate {
  display: block;
  margin: 0.4rem auto 0.8rem auto;
}
.review_popup_wrap > .body > .write > textarea {
  width: 100%;
  height: 240px;
  margin-bottom: 1rem;
  resize: none;
}
.review_popup_wrap > .body > .write > h3 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0 0.4rem 0;
  padding: 0;
}
.review_popup_wrap > .body > .write > input[type="file"].image_upload {
  display: none;
}
.review_popup_wrap > .body > .write > .images {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.review_popup_wrap > .body > .write > .images > .image,
.review_popup_wrap > .body > .write > .images > .upload {
  position: relative;
  width: calc((100% - 0.4rem - 0.4rem) / 3);
  margin-right: 0.4rem;

  background-color: #efefef;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.review_popup_wrap > .body > .write > .images > .image::after {
  display: block;
  content: "";
  padding-top: 100%;
}
.review_popup_wrap > .body > .write > .images > .image:nth-child(3n) {
  margin-right: 0;
}
.review_popup_wrap > .body > .write > .images > .upload {
  background-color: transparent;
  margin-right: 0;
}
.review_popup_wrap > .body > .write > .images > .upload::before {
  display: block;
  content: "";
  padding-top: 100%;
}
.review_popup_wrap > .body > .write > .images > .upload::after {
  display: block;
  content: "";
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);

  width: 32px;
  height: 32px;

  background: #e74356 url("../../assets/img/icon_plus_white.svg") no-repeat
    center center;
  background-size: auto 60%;
  border-radius: 50%;

  cursor: pointer;
}
.review_popup_wrap > .body > .write > .images > .image > .delete {
  display: none;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);

  width: 32px;
  height: 32px;

  background: #333 url("../../assets/img/icon_close_white.svg") no-repeat center
    center;
  background-size: auto 60%;
  border-radius: 50%;

  cursor: pointer;
}
.review_popup_wrap > .body > .write > .images > .image:hover > .delete {
  display: block;
}

.review_popup_wrap > .body > .view > .view_review {
  position: absolute;
  right: 0;
  z-index: 10;
  font-size: 1rem;
  padding: 1rem;

  background: #fff;
  border: 1px solid #efefef;

  width: 320px;
  max-width: 90vw;
}

.review_popup_wrap > .body > .view > .date {
  color: #808080;
  margin: 0 0 0.4rem 0;
}
.review_popup_wrap > .body > .view > .score {
  width: 120px;
  margin: 0 0 0.8rem;
}
.review_popup_wrap > .body > .view > .content {
  margin: 0 0 0.8rem;
  white-space: pre-line;
}
.review_popup_wrap > .body > .view > .images {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.review_popup_wrap > .body > .view > .images > .image {
  position: relative;
  width: 96px;
  height: 96px;
  margin: 0 0.4rem 0 0;
  background-color: #efefef;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.review_popup_wrap > .body > .view > .images > .image::after {
  padding-top: 100%;
}
</style>
